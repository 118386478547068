export const QUEUES_DATA = 'QUEUES_DATA';
export const QUEUES_DATA_VALIDATION = 'QUEUES_DATA_VALIDATION';
export const ADD_QUEUES_DATA = 'ADD_QUEUES_DATA';

export const SET_QUEUE_NAME = 'SET_QUEUE_NAME';
export const QUEUES_LENGTH = 'QUEUES_LENGTH';
export const GET_DOCUMENTTYPE_FOR_QUEUE = 'GET_DOCUMENTTYPE_FOR_QUEUE';

export const SET_VALIDATION_DATA = 'SET_VALIDATION_DATA';
export const SET_VALIDATION_COLUMNS = 'SET_VALIDATION_COLUMNS';

export const SET_DATA_CHECK = 'SET_DATA_CHECK';
export const SET_RULES_CHECK = 'SET_RULES_CHECK';

export const SET_PERSISTED_TABLE_STATE = 'SET_PERSISTED_TABLE_STATE';