import * as queuesActionType from './queuesActionTypes';

const initialQueuesState = {
  add_queues_data: {
    name: '',
    method: '',
  },
  queues_data: [],
  documenttypes_for_queue: {},
  queue_length:0,
  validation_queues_data: [],
  validation_columns: [],
  validation_data: [],
  data_check: false,
  rules_check: false,
  persisted_table_state: null,
};

const processorReducer = (state = initialQueuesState, { type, payload }) => {
  switch (type) {
    case queuesActionType.QUEUES_DATA:
      return {
        ...state,
        queues_data: payload,
      };
    case queuesActionType.ADD_QUEUES_DATA:
      return {
        ...state,
        add_queues_data: payload,
      };
    case queuesActionType.SET_QUEUE_NAME:
      return {
        ...state,
        queue_name: payload,
      };
    case queuesActionType.QUEUES_LENGTH:
      return {
        ...state,
        queue_length: payload,
      };
    case queuesActionType.GET_DOCUMENTTYPE_FOR_QUEUE:
      return {
        ...state,
        documenttypes_for_queue: payload,
      };
    case queuesActionType.QUEUES_DATA_VALIDATION:
      return {
        ...state,
        validation_queues_data: payload,
      };
    case queuesActionType.SET_VALIDATION_COLUMNS:
      return {
        ...state,
        validation_columns: payload,
      };
    case queuesActionType.SET_VALIDATION_DATA:
      return {
        ...state,
        validation_data: payload,
      };
    case queuesActionType.SET_DATA_CHECK:
      return {
        ...state,
        data_check: payload,
      };
    case queuesActionType.SET_RULES_CHECK:
      return {
        ...state,
        rules_check: payload,
      };
    case queuesActionType.SET_PERSISTED_TABLE_STATE:
      return {
        ...state,
        persisted_table_state: payload,
      };
    default:
      return state;
  }
};

export default processorReducer;
