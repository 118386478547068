// import { createStore, applyMiddleware } from 'redux';
// import { thunk } from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
// import reducer from './rootReducer';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// export const storeObj = {};
// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['processor', 'fields', 'documents', 'queues', 'user'],
// };

// export default function setup() {
//   const persistedReducer = persistReducer(persistConfig, reducer);

//   const logger = createLogger({
//     collapsed: true,
//   });
//   const Store = createStore(
//     persistedReducer,
//     undefined,
//     composeWithDevTools(applyMiddleware(thunk, logger))
//   );
//   const persistor = persistStore(Store, null, () => { });
//   storeObj.store = Store;
//   console.log(storeObj);
//   return { persistor, Store };
//   // export Store;
// }

import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import reducer from './rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const storeObj = {};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['processor', 'fields', 'documents', 'user', 'test'],
  whitelist: ['queues'],
};

export default function setup() {
  const persistedReducer = persistReducer(persistConfig, reducer);

  const logger = createLogger({
    collapsed: true,
  });

  // Safely use composeWithDevTools or fallback to compose
  const composeEnhancers =
    (typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const Store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk, logger))
  );

  const persistor = persistStore(Store, null, () => {});
  storeObj.store = Store;

  console.log(storeObj);
  return { persistor, Store };
}



// import { configureStore } from '@reduxjs/toolkit';
// import { persistStore, persistReducer } from 'redux-persist';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import storage from 'redux-persist/lib/storage';
// import reducer from './rootReducer';

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, reducer);

// const store = configureStore(
//   persistedReducer,
//   composeWithDevTools()
//   // middleware: (getDefaultMiddleware) =>
//   //   getDefaultMiddleware({
//   //     serializableCheck: false, // Disable if you have non-serializable data, like functions in state
//   //   }),
// );

// const persistor = persistStore(store);

// export { store, persistor };
