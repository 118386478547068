import * as documentActionType from './documentActionType';

const initialDocumentState = {
  document_data: [],
  fields_for_document: {},
  update_fields_for_document: [],
  document_size: [],
  validation_document_data: [],
  validation_document_data_gt: [],
  document_data_queue: [],
};

const documentReducer = (state = initialDocumentState, { type, payload }) => {
  switch (type) {
    case documentActionType.DOCUMENT_DATA:
      return {
        ...state,
        document_data: payload,
      };
      case documentActionType.DOCUMENT_DATA_SIZE:
      return {
        ...state,
        document_size: payload,
      };
    case documentActionType.ADD_DOCUMENT:
      return {
        ...state,
        add_document: payload,
      };
    case documentActionType.GET_FIELDS_FOR_DOCUMENT:
      return {
        ...state,
        fields_for_document: payload,
      };
    case documentActionType.SET_UPDATE_FIELDS_FOR_DOCUMENT:
      return {
        ...state,
        update_fields_for_document: payload,
      };
    case documentActionType.GET_VALIDATION_DOCUMENT_DATA:
      return {
        ...state,
        validation_document_data: payload,
      };
    case documentActionType.GET_VALIDATION_DOCUMENT_DATA_GT:
      return {
        ...state,
        validation_document_data_gt: payload,
      };
      case documentActionType.GET_DOCUMENT_DATA_QUEUE:
        return {
          ...state,
          document_data_queue: payload,
        };
    default:
      return state;
  }
};

export default documentReducer;
