import { v4 as uuidv4 } from 'uuid';

export const IND_ONE = 'https://onesit.in-d.ai/api/';
export const IND_ONE_SIT = 'https://one.in-d.ai/api/';
export const ADMIN_URL = 'https://financedemo.in-d.ai/admin/';   
export const PRODUCT_IP = 'https://financedemo.in-d.ai/';  
// export const ADMIN_URL = 'https://ldp.intainabs.com/admin/';   
// export const PRODUCT_IP = 'https://ldp.intainabs.com/';   
// export const ADMIN_URL = 'https://ldptest.intainabs.com/admin/';   
// export const PRODUCT_IP = 'https://ldptest.intainabs.com/';  
// export const ADMIN_URL = 'http://35.207.210.124/admin/';
// export const PRODUCT_IP = 'http://35.207.210.124/';

export const PATH = {
  Login: 'users/token/',
  isAdmin: 'api/login',
  wrong_pass_return: 'users/wrong_pass_return/',
  wrong_pass_update: 'users/wrong_pass_update/',
  profiledetails: 'users/get_user_details/',
  passwordexpiry: 'users/pass_expiry_check/',
  profileupdate: 'users/update_user_details/',
  changepassword: 'change_password/',
  passwordreset: 'password_reset/',
  passwordresetconfirm: 'password_reset/confirm/',
  Registartion: 'users/',
  export_combined: 'api/export_combined',
  export_caseid_excel: 'api/export',
  export_excel_case: 'api/export',
  export_json_case: 'api/export_json',

  // display_processor: "display_processor",
  display_processor_batch: 'displayprocessor_batch',
  display_processor: 'display_processors',
  add_processor: 'add_processor',
  delete_processor: 'delete_processor',
  edit_processor: 'edit_processor',
  // fields
  display_fields: 'display_fields',
  add_fields: 'add_field',
  update_fields: 'edit_fields',
  delete_field: 'delete_field',
  display_fields_validation: 'display_fields_validation', //for validation
  // documents
  displaydocument_batch: 'displaydocument_batch',
  display_document: 'display_documenttypes',
  display_documenttypes_queue: 'display_documenttypes_queue', //specific for queue
  display_documenttypes_validation: 'display_documenttypes_validation', //for validation
  display_documenttypesgt_validation: 'display_documenttypesgt_validation', //for validation
  add_document: 'add_documenttype',
  delete_document: 'delete_documenttype',
  fields_document: 'fields_processor',
  edit_document: 'edit_documenttype',
  // display_queues: "display_queues",
  display_queues_batch: 'displayqueue_batch',
  display_queues: 'display_queues',
  display_queue_validation: 'display_queue_validation', //for validation
  add_queues: 'add_queue',
  map_processor: 'map_processors',
  unmap_processor: 'unmap_processors',
  map_document: 'map_documenttypes',
  unmap_document: 'unmap_documenttypes',
  map_documenttypes_field_queue: 'map_documenttypes_field_queue', //specific for queue
  delete_queues: 'delete_queue',
  edit_queue: 'edit_queue',
  queues_and_documents: 'queues_and_documents',

  // user apis
  reviewStatus: 'api/review_tab',
  pendingstatus: 'api/pending_tab',
  exportedstatus: 'api/exported_tab',
  deletestatus: 'api/deleted_tab',
  deletedocuments: 'api/soft_delete_batches', //soft delete
  zipbatch: 'zip_batch',
  upload_documents: 'api/upload_batch',
  admin_dashboard: 'display_batches',
  userSetPassword: 'activate_processor',

  //digitize section
  get_review_document: 'api/validation_screen',
  get_ui_validation: 'api/preview',
  graphs: 'graphs',
  mark_as_reviewed: 'api/mark_as_reviewed',
  get_digitize_document: 'api/validation',
  get_extracted_data: 'api/jumbled_words',
  //preProcessing && extraction
  caseProcessing: 'api/process',
  extractionProcess: 'api/extract_batch',
  update_casedata: 'api/update_job',
  view_image: 'view_image',

  // Admin
  delete_caseId: 'api/hard_delete_batches', //hard delete

  // Processor
  get_password: 'get_password',
  recover_caseid: 'api/recover_batches',
  split_merge: 'api/split_merge',
  read_table: 'api/read_table',

  //test_screen
  testDisplay: 'api/test/display_test',
  addTest: 'api/test/add_test',
  editTest: 'api/test/edit_test',
  deleteTest: 'api/test/delete_test',

  AddGroup: 'api/test/add_group',
  DisplayGroup: 'api/test/display_group',

  //SFTP EndPoints
  list_mount_contract: 'api/list_mount_contract',
  list_mount_loanTape: 'api/list_mount_lms',

  //validation
  add_valdatacheck: 'api/test/add_valdatacheck',
  display_valdatacheck: 'api/test/display_valdatacheck',
  copy_valdatacheck: 'api/test/copy_valdatacheck',
};

// All Constants
export const PTC = '#48454A'; // primary text color

export const defaultFields = [
  'Net Banking',
  'Payment Gateway',
  'Auto Debit',
  'Bank Charges',
  'ATM Transaction',
  'Card Transaction',
  'Bank Credit',
  'Bill Pay',
  'Loan',
  'Demand Draft',
  'UPI',
  'Insurance Credit',
  'Insurance purchase',
  'Medical Payment',
  'Alcohol Purchase',
  'Minimum Balance Charge',
  'Cheque',
  'Cheque Bounce Charge',
  'Cheque Number',
  'Negative Balance Charge',
  'SIP',
  'Mutual Fund',
  'FD Payment',
  'Society Maintenance',
  'Property Tax',
  'Adv Income Tax Payment',
  'Credit',
  'Credit Card Payment',
  'Balance',
  'Debit',
  'Date',
  'description',
  'Transaction Type',
  'Salary',
  'Gross Salary',
  'Net Salary',
  'Period Of Pay',
  'Employer Address',
  'Employer Name',
  'Date Of Joining',
  'UAN Number',
  'PAN Number',
  'Employee Address',
  'Employee Designation',
  'Employee Id',
  'Employee Name',
  'Bank Name',
  'Type of Account',
  'IFSC Code',
  'Account Open Date',
  'Account Holder Name',
  'Account Number',
];
export const routers = ['/', '/processers', '/documents', '/fields', '/uploadDashboard', '/queuesMapProcessors', '/processordashboard','/editdocument','/test'];
export const defaultDocTypes = [
  'Bank Statement',
  'Salary Slip',
  'Bank Statements',
  'Salary Slips',
];
export const customMuiTableRowStyle = {
  // height: '44px',
  // display: 'flex',
  // alignItems: 'center',
  // marginLeft: '1rem',
};
let uuid = `Temp${uuidv4()}`;
export const newTableData = {
  row_coordinates: [],
  column_coordinates: [],
  columns: ['', '', '', '', '', ''],
  gridId: uuid,
  is_table: true,
  magicGrid: {},
  data: [
    [
      {
        word: '',
        rowNo: 2,
        columnNo: 1,
        coordinates: {
          id: Math.floor(Math.random() * 10000),
        },
        token: [],
      },
      {
        word: '',
        rowNo: 2,
        columnNo: 2,
        coordinates: { id: Math.floor(Math.random() * 10000) },
        token: [],
      },
      {
        word: '',
        rowNo: 2,
        columnNo: 3,
        coordinates: { id: Math.floor(Math.random() * 10000) },
        token: [],
      },
      {
        word: '',
        rowNo: 2,
        columnNo: 4,
        coordinates: { id: Math.floor(Math.random() * 10000) },
        token: [],
      },
      {
        word: '',
        rowNo: 2,
        columnNo: 5,
        coordinates: { id: Math.floor(Math.random() * 10000) },
        token: [],
      },
      {
        word: '',
        rowNo: 2,
        columnNo: 6,
        coordinates: { id: Math.floor(Math.random() * 10000) },
        token: [],
      },
    ],
  ],
};

export const newTableCellData = {
  column_cords: [],
  confidence_score: 0.92,
  gridId: uuid,
  height: 0,
  is_table: true,
  id: uuid,
  left: 0,
  rows: [],
  tableno: 0,
  top: 0,
  width: 0,
};



// * Transaction types
export const transactionTypes = [
  'Others',
  'ATM Transaction',
  'Card Transaction',
  'Bank Credit',
  'Bill Pay',
  'Loan',
  'Demand Draft',
  'UPI',
  'Net Banking',
  'Bank Charges',
  'Cheque',
  'Salary',
  'Payment Gateway',
  'Auto Debit',
  'Insurance Credits',
  'Medical Payments',
  'Alcohol Purchase',
  'Minimum Balance Charges',
  'Cheque Bounce Charges',
  'Negative Balance Charges',
  'SIP',
  'Mutual Funds',
  'Insurance purchase',
  'FDs',
  'Society Maintenance',
  'Property Tax',
  'Advance Income tax payments',
  'Credit Card payment',
  'Insurance payouts',
  'Sweep In/Out',
];
