import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './ModalP.css';
import ButtonPrimary from '../../Button/ButtonPrimary';
const Modal = ({
  handleClick,
  handleClose,
  name,
  desc,
  header,
  modal,
  logout,
  functionText,
  body,
  addProcessor,
  disabled,
  Child,
}) => {
  return (
    <div>
      <div className='data' style={{ width: '100%' }}>
        <Dialog
          open={handleClick}
          onClose={handleClose}
          scroll={'paper'}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          className='logout_popup'
          style={{ overflow: 'scroll' }}
        >
          <div style={{ width: '100%' }}>
            <DialogTitle id='alert-dialog-title'>{header}</DialogTitle>
            <hr className='logoutborder' />
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {desc}
              </DialogContentText>
              {Child}
            </DialogContent>
            <DialogActions className='modalfooter'>
              <React.Fragment>
                <Button
                  onClick={handleClose}
                  color='primary'
                  className='cancelbutton'
                >
                  Cancel
                </Button>
                <ButtonPrimary
                  onClick={logout ? logout : () => addProcessor()}
                  disabled={disabled}
                  actualText={functionText}
                />
              </React.Fragment>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default Modal;
