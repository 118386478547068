import React from 'react';
import { Outlet } from 'react-router-dom';
import SideNavbar from '../Components/PSideBar/SideNavbar';
import './Layouts.css';

// export default function LayoutRoute({ component: Component, ...rest }) {
//     return (
//       <Route
//         {...rest}
//         render={(props) => (
//           <CustomLayout>{Component && <Component {...props} />}</CustomLayout>
//         )}
//       />
//     );
//   }
  function ProcessorLayout() {
    return (
      <React.Fragment>
        <SideNavbar processorModule />
        <div id="mainContent">
          <div className="inner-wrapper">
            <Outlet/>
            </div>
        </div>
      </React.Fragment>
    );
  };

  export default ProcessorLayout;