import axios from 'axios';
import { PATH, ADMIN_URL } from '../../Constants';
import * as documentActionType from './documentActionType';
import toast from 'react-hot-toast';
import { redirectToLogin } from '../Common/CommonAction';

var token = sessionStorage.getItem('token');
var admin_email = sessionStorage.getItem('name');
var e_id = parseInt(sessionStorage.getItem('e_id'));

export const getDocumentData = (page_no = 1, rows_per_page = 100) => async (dispatch, getState) => {
  const { start, end, total } = getState().common.pagination;
  console.log('getstate', getState());
  const data1 ={
    page_no,
    rows_per_page,
    e_id: e_id
  };
  const args = {
    method: 'POST',
    url: `${ADMIN_URL}${PATH.display_document}`,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: data1,
  };

  try {
    let api = await axios(args);
    if (api.status == 200) {
      let data = [];
      api.data.result.Document_Types.map((e) => {
        data.push([
          e.name,
          e.queues_count,
          Object.entries(e.queues_mapped)
              .filter((f) => f[1] === 'Yes')
              .flat()
              .filter((g) => g !== 'Yes'),
          ,
          e.fields_count,
          e.id,
          e.field_ids,
          e.field_names[0],
        ]);
      });
      sessionStorage.setItem('total_count',api.data.result.Total_Document_Types);
      dispatch({
        type: documentActionType.DOCUMENT_DATA,
        payload: data,
      });
      dispatch({
        type: documentActionType.DOCUMENT_DATA_SIZE,
        payload: api.data.result.Total_Document_Types,
      });

      console.log(data);
    } else {
      //toast.error('Something Went Wrong');
      dispatch({
        type: documentActionType.DOCUMENT_DATA,
        payload: [],
      });
    }
  } catch (error) {
    if (error && error.response) {
      dispatch({
        type: documentActionType.DOCUMENT_DATA,
        payload: [],
      });
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const getDocumentDataQueue = (page_no = 1, rows_per_page = 100) => async (dispatch, getState) => {
  const data1 ={
    q_id: sessionStorage.getItem('queueid'),
    page_no,
    rows_per_page,
    e_id: e_id
  };
  const args = {
    method: 'POST',
    url: `${ADMIN_URL}${PATH.display_documenttypes_queue}`,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: data1,
  };

  try {
    let api = await axios(args);
    if (api.status == 200) {
      let data = [];
      api.data.result.Document_Types.map((e) => {
        data.push([
          e.name,
          e.queues_count,
          Object.entries(e.queues_mapped)
              .filter((f) => f[1] === 'Yes')
              .flat()
              .filter((g) => g !== 'Yes'),
              ,
          Object.entries(e.field_mapped)
          .filter(([_, value]) => value === 'Yes')
          .map(([key]) => key),
          e.id,
          e.field_ids,
         // e.field_names[0],
        ]);
      });
      sessionStorage.setItem('total_count',api.data.result.Total_Document_Types);
      dispatch({
        type: documentActionType.GET_DOCUMENT_DATA_QUEUE,
        payload: data,
      });
      console.log(data,'datadocumentqueue');
    } else {
      //toast.error('Something Went Wrong');
      dispatch({
        type: documentActionType.GET_DOCUMENT_DATA_QUEUE,
        payload: [],
      });
    }
  } catch (error) {
    if (error && error.response) {
      dispatch({
        type: documentActionType.GET_DOCUMENT_DATA_QUEUE,
        payload: [],
      });
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
export const getDocumentDataQueueMapField = (field_ids, d_id) => async (dispatch, getState) => {
  const args = {
    method: 'POST',
    url: `${ADMIN_URL}${PATH.map_documenttypes_field_queue}`,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: {
      q_id: sessionStorage.getItem('queueid'),
      e_id: e_id,
      f_ids: field_ids,
      d_id,
    },
  };

  return toast.promise(axios(args), {
    loading: "Mapping document fields...",
    success: (api) => api.data.message || "Fields mapped successfully",
    error: (error) => {
      if (error.response?.status === 401) {
        redirectToLogin();
      }
      return error.response?.data?.message || "Failed to map fields";
    },
  });
};

export const setDocumentData = (fields) => async (dispatch) => {
  dispatch({
    type: documentActionType.ADD_DOCUMENT,
    payload: fields,
  });
};

export const addDocumentData = (fields) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${ADMIN_URL}${PATH.add_document}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: {...fields, e_id},
  };
  console.log('check add processor,', args);
  return toast.promise(axios(args), {
    loading: "Adding Document...",
    success: (api) => api.data.message || "Document added successfully",
    error: (error) => {
      if (error.response?.status === 401) {
        redirectToLogin();
      }
      return error.response?.data?.message || "Failed to add Document";
    },
  });
  // try {
  //   let api = await axios(args);
  //   if (api.status == 200) {
  //     //toast.success(api?.data?.result?.message);
  //     return true;
  //   } else {
  //    // toast.error('Something Went Wrong');
  //     return false;
  //   }
  //   //  console.log(api,"status api")
  // } catch (error) {
  //   if (error && error.response) {
  //     // toast.error(error?.response?.data?.message);
  //     if (error.response.status === 401) {
  //       redirectToLogin();
  //     }
  //     return false;
  //   }
  // }
};
export const deleteDocuments = (fields) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${ADMIN_URL}${PATH.delete_document}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: {...fields, e_id},
  };
  console.log('check add processor,', args);
  return toast.promise(axios(args), {
    loading: "Deleting Document...",
    success: (api) => api.data.message || "Document deleted successfully",
    error: (error) => {
      if (error.response?.status === 401) {
        redirectToLogin();
      }
      return error.response?.data?.message || "Failed to delete Document";
    },
  });
  // try {
  //   let api = await axios(args);
  //   if (api.status == 200) {
  //     //toast.success(api?.data?.result?.message);
  //     return true;
  //   } else {
  //     //toast.error('Something Went Wrong');
  //     return false;
  //   }
  //   //  console.log(api,"status api")
  // } catch (error) {
  //   if (error && error.response) {
  //     // toast.error(error?.response?.data?.message);
  //     if (error.response.status === 401) {
  //       redirectToLogin();
  //     }
  //     return false;
  //   }
  // }
};

export const mapDocumentsAction = (index) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${ADMIN_URL}${PATH.map_document}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: {...index, e_id},
  };
  console.log('check add processor,', args);
  return toast.promise(axios(args), {
    loading: "Mapping Document...",
    success: (api) => api.data.message || "Document mapped successfully",
    error: (error) => {
      if (error.response?.status === 401) {
        redirectToLogin();
      }
      return error.response?.data?.message || "Failed to map Document";
    },
  });
};
// * UNMAP DOCUMENT
export const unMapDocumentsAction = (data) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${ADMIN_URL}${PATH.unmap_document}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: {...data, e_id},
  };
  return toast.promise(axios(args), {
    loading: "UnMapping Document...",
    success: (api) => api.data.message || "Document unmapped successfully",
    error: (error) => {
      if (error.response?.status === 401) {
        redirectToLogin();
      }
      return error.response?.data?.message || "Failed to unmap Document";
    },
  });
};
// Get fields for document
export const getFieldsForDocument = () => async (dispatch) => {
  const args = {
    method: 'GET',
    url: `${ADMIN_URL}${PATH.fields_document}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v3.0',
    },
  };

  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log('check Api', api);
      dispatch({
        type: documentActionType.GET_FIELDS_FOR_DOCUMENT,
        payload: api.data,
      });
    } else {
      //toast.error('Something Went Wrong');
      dispatch({
        type: documentActionType.GET_FIELDS_FOR_DOCUMENT,
        payload: {},
      });
    }
  } catch (error) {
    if (error && error.response) {
      dispatch({
        type: documentActionType.GET_FIELDS_FOR_DOCUMENT,
        payload: {},
      });
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// * Edit/Update Document
export const updateDocument = (data) => async (dispatch) => {
  const args = {
    method: 'PATCH',
    url: `${ADMIN_URL}${PATH.edit_document}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: {...data, e_id},
  };
  return toast.promise(axios(args), {
    loading: "Updating Document...",
    success: (api) => api.data.message || "Document updated successfully",
    error: (error) => {
      if (error.response?.status === 401) {
        redirectToLogin();
      }
      return error.response?.data?.message || "Failed to update Document";
    },
  });
  // try {
  //   let api = await axios(args);
  //   if (api.status === 200) {
  //     //toast.success(api?.data?.message);
  //     return true;
  //   } else {
  //     //toast.error(api.data.message);
  //     return false;
  //   }
  // } catch (error) {
  //   if (error && error.response) {
  //     // toast.error(error?.response?.data?.message);
  //     if (error.response.status === 401) {
  //       redirectToLogin();
  //     }
  //     return false;
  //   }
  // }
};
// setUpdateFieldsForDocument
export const setUpdateFieldsForDocument = (data) => async (dispatch) => {
  dispatch({
    type: documentActionType.SET_UPDATE_FIELDS_FOR_DOCUMENT,
    payload: data,
  });
};

export const getValidationDocumentData = () => async (dispatch) => {
  const data1 ={
    q_id : sessionStorage.getItem('queueid'),
    e_id: e_id
  };
  const args = {
    method: 'POST',
    url: `${ADMIN_URL}${PATH.display_documenttypes_validation}`,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: data1,
  };

  try {
    let api = await axios(args);
    if (api.status == 200) {
      let data = [];
      api.data.result.map((e) => {
        data.push(e.name);
      });
      // setDisplayProcessor(data);
      //sessionStorage.setItem('total_count',api.data.result.Total_Document_Types);
      dispatch({
        type: documentActionType.GET_VALIDATION_DOCUMENT_DATA,
        payload: data,
      });
      console.log(data);
    } else {
      //toast.error('Something Went Wrong');
      dispatch({
        type: documentActionType.GET_VALIDATION_DOCUMENT_DATA,
        payload: [],
      });
    }
  } catch (error) {
    if (error && error.response) {
      dispatch({
        type: documentActionType.GET_VALIDATION_DOCUMENT_DATA,
        payload: [],
      });
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
export const getValidationDocumentDatagt = () => async (dispatch) => {
  const data1 ={
    q_id : sessionStorage.getItem('queueid'),
    e_id: e_id
  };
  const args = {
    method: 'POST',
    url: `${ADMIN_URL}${PATH.display_documenttypesgt_validation}`,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: data1,
  };

  try {
    let api = await axios(args);
    if (api.status == 200) {
      let data = [];
      api.data.result.map((e) => {
        data.push(e.name);
      });
      // setDisplayProcessor(data);
      //sessionStorage.setItem('total_count',api.data.result.Total_Document_Types);
      dispatch({
        type: documentActionType.GET_VALIDATION_DOCUMENT_DATA_GT,
        payload: data,
      });
      console.log(data);
    } else {
      //toast.error('Something Went Wrong');
      dispatch({
        type: documentActionType.GET_VALIDATION_DOCUMENT_DATA_GT,
        payload: [],
      });
    }
  } catch (error) {
    if (error && error.response) {
      dispatch({
        type: documentActionType.GET_VALIDATION_DOCUMENT_DATA_GT,
        payload: [],
      });
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
