import React from 'react';
import { Outlet } from 'react-router-dom';
import SideNavbar from '../Components/SideNavbar/SideNavbar';
import './Layouts.css';

// export default function LayoutRoute({ path, element: Component }) {
//   return (
//     <Routes>
//       <Route
//         path={path}
//         element={
//           <CustomLayout>
//             {Component && <Component />}
//           </CustomLayout>
//         }
//       />
//     </Routes>
//   );
// }

function CustomLayout({ module }) {
  return (
    <>
      <SideNavbar module={module} />
      <div id='mainContent'>
        <div className='inner-wrapper'>
          {/* {children} */}
          <Outlet/>
          </div>
      </div>
    </>
  );
};

export default CustomLayout;


